<script>
  // # # # # # # # # # # # # #
  //
  //  CLOCK
  //
  // # # # # # # # # # # # # #

  function berlinTime(){
    var d = new Date();
    var cet = new Intl.DateTimeFormat('de-DE', {
      timeZone: 'CET',
      hour: 'numeric',
      minute: 'numeric'
    }).format(d)
    time = cet;
    return cet;
  }

  var time = berlinTime();
  window.setInterval(berlinTime, 10000);
  berlinTime();

</script>

<style lang="scss">.clock-berlin {
  font-family: "IBM Plex Mono", monospace;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #f0f0f0;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  word-spacing: -0.3em; }
  .clock-berlin a {
    color: white;
    text-decoration: none; }
  @media (max-width: 800px) {
    .clock-berlin {
      top: 80px;
      z-index: 1; } }
</style>

<div class="clock-berlin">
    {time}&nbsp;CET
</div>