<script>
  // # # # # # # # # # # # # #
  //
  //  USERNAME DIALOG
  //
  // # # # # # # # # # # # # #
  // *** IMPORTS
  import { onMount, createEventDispatcher } from "svelte"
  import { fade } from "svelte/transition"
  import { quartOut } from "svelte/easing"

  // *** STORES
  import { localUserName } from "../stores.js"

  // *** CONSTANTS
  const dispatch = createEventDispatcher()

  // *** VARIABLES
  let username = $localUserName ? $localUserName : ""

  // *** DOM REFERENCES
  let inputEl = {}

  onMount(async () => {
    if (inputEl) {
      inputEl.focus()
    }
  })
</script>

<style lang="scss">.username-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000; }
  .username-dialog .box {
    font-family: "IBM Plex Mono", monospace;
    padding: 40px;
    font-size: 18px;
    text-align: center;
    user-select: none;
    border-radius: 4px;
    background: #f0f0f0;
    display: flex; }
    @media (max-width: 800px) {
      .username-dialog .box {
        font-size: 12px; } }
    .username-dialog .box input {
      font-family: "IBM Plex Mono", monospace;
      font-size: 14px;
      float: left;
      width: calc(100% - 60px);
      display: block;
      background: #c5c5c5;
      border: 1px solid black;
      color: black;
      border-radius: 4px;
      padding: 12px 18px;
      outline: none;
      height: 30px;
      margin-right: 24px; }
      .username-dialog .box input.smaller {
        width: calc(100% - 110px); }
      .username-dialog .box input:focus {
        border: 1px solid #f0f0f0; }
      .username-dialog .box input::placeholder {
        opacity: 0.7; }
      .username-dialog .box input input[type="text"] {
        -webkit-appearance: none; }
        .username-dialog .box input input[type="text"]::placeholder {
          color: #f0f0f0; }
      .username-dialog .box input select {
        -webkit-appearance: none; }
    .username-dialog .box div {
      font-family: "IBM Plex Mono", monospace;
      font-size: 14px;
      float: right;
      display: block;
      width: 100px;
      background: transparent;
      color: black;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      margin-right: 12px; }
    .username-dialog .box button {
      font-family: "IBM Plex Mono", monospace;
      font-size: 90%;
      text-transform: uppercase;
      float: right;
      display: block;
      width: 100px;
      background: transparent;
      border: 1px solid black;
      color: black;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px; }
      .username-dialog .box button:hover {
        border: 1px solid #f0f0f0; }
</style>

<div
  class="username-dialog"
  transition:fade={{ duration: 400, easing: quartOut }}>
  <div class="box">
    <input
      type="text"
      bind:this={inputEl}
      bind:value={username}
      placeholder="Choose your username"
      on:keydown={e => {
        if (e.keyCode == 13) dispatch('username', { username: username })
      }} />
    <button
      on:click={e => {
        dispatch('username', { username: username })
      }}>Enter</button>
  </div>
</div>
