<script>
  // # # # # # # # # # # # # #
  //
  //  Menu
  //
  // # # # # # # # # # # # # #
  // *** IMPORTS
  import { createEventDispatcher } from "svelte"

  // *** CONSTANTS
  const dispatch = createEventDispatcher()
</script>

<style lang="scss">.menu-container {
  height: 100%;
  color: black;
  font-size: 12px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding-left: 12px;
  padding-right: 12px; }
  .menu-container .menu-item {
    font-weight: bold;
    padding-right: 24px;
    float: left;
    cursor: pointer;
    color: black;
    text-decoration: none; }
    .menu-container .menu-item:hover {
      text-decoration: underline; }
  .menu-container .login {
    padding-right: 0px;
    justify-self: end; }
</style>

<div class="menu-container">
  <div>
    <a href="/projects" class="menu-item">Projects</a>
    <a href="/pages/guide" class="menu-item">Guide</a>
    <a href="/pages/about" class="menu-item">About</a>
  </div>

  <span
    on:click={e => {
      dispatch('username')
    }}
    class="menu-item login">Change name</span>
</div>
